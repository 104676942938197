import * as THREE from "three";
import { gsap } from "gsap";
import { isSP } from "../../functions/isSP";
import { GUI } from "dat.gui";

import { Mesh } from "./mesh.es6";
import { SpanWrap } from "../../../util/spanwrap";

export class Slide {
    constructor(stage) {
        this.textureURLArray = window.yet.textures;
        this.slideIndex = this.setInitialIndex();
        this.initialLoadIndex = this.setInitialLoadIndex();
        this.meshList = [];
        this.position = new THREE.Vector3(0, 0, 0);
        this.mouse = { x: 0, y: 0 };
        this.v = new THREE.Vector2(-0.0005, 0);

        //status
        this.slideTexture = [];
        this.stage = stage;
        this.group = null;

        //timer
        this.timer = [];
        this.slidePause = false;
    }

    init() {
        this.initSlideTexture();
        this.setMesh();
        this.createDot();
        this.setNav();
        this.setTitleDivide();

        //this.setMouseEvent();
    }

    initSlideTexture() {
        this.textureURLArray.forEach((el) => {
            this.slideTexture.push(null);
        });
    }

    setGUI() {
        const gui = new GUI();
        const groupPosition = gui.addFolder("GroupPosition");
        groupPosition.add(this.group.position, "x", -5, 5);
        groupPosition.add(this.group.position, "y", -5, 5);
        groupPosition.add(this.group.position, "z", -5, 5);
    }

    setMesh() {
        const amount = 40;
        const group = new THREE.Group();
        const stage = this.stage;
        const slideTexture = this.slideTexture;
        let gridTexture = null;
        const initialIndex = this.slideIndex.count;

        this.group = group;
        this.slideTexture = slideTexture;

        const textureLoader = new THREE.TextureLoader();
        const textures = window.yet.textures; //WPから受け取った配列

        const gridTextureURL = "/wp/wp-content/themes/yet/assets/img/top/mv/ph_line.png";

        //非同期ロード
        //promise allだと順不同になるため一旦検討
        slideTexture[this.initialLoadIndex[1]] = textureLoader.load(textures[this.initialLoadIndex[1]]);
        slideTexture[this.initialLoadIndex[2]] = textureLoader.load(textures[this.initialLoadIndex[2]]);
        gridTexture = textureLoader.load(gridTextureURL);

        //初期スライドロード
        textureLoader.load(textures[this.initialLoadIndex[0]], (tex) => {
            slideTexture[this.initialLoadIndex[0]] = tex;

            for (let i = 0; i < amount; i++) {
                const mesh = new Mesh({ stage, group, slideTexture, gridTexture, initialIndex, index: i });
                this.meshList.push(mesh);
                mesh.init();
            }

            //シーンにグループ追加
            stage.scene.add(group);
            group.rotation.x = -Math.PI * 0.5;
            //group.position.y = 0.3;
            //group.position.x = 0.5;

            group.position.y = isSP() ? 0.25 : 0.35;
            group.position.x = isSP() ? 0.5 : 0.4;

            const skipDelay = document.querySelector(".skip") ? 0 : 1000;

            //ローダー解除,ロード関数実行
            setTimeout(() => {
                this.onLoad();
            }, skipDelay);
        });
    }

    setInitialIndex() {
        //ロード時ランダムに読み込むインデックスと前後のロードを決定
        const randIndex = Math.floor(this.textureURLArray.length * Math.random());
        return { count: randIndex };
    }

    setInitialLoadIndex() {
        const current = this.slideIndex.count;
        let next = this.slideIndex.count + 1;
        let prev = this.slideIndex.count - 1;

        if (next > this.textureURLArray.length - 1) {
            next = 0;
        }

        if (prev < 0) {
            prev = this.textureURLArray.length - 1;
        }

        return [current, next, prev];
    }

    setMouseEvent() {
        if (isSP()) {
            return;
        }

        window.addEventListener("mousemove", (e) => {
            const x = (window.innerWidth / 2 - e.clientX) / 4000;
            const y = (window.innerHeight / 2 - e.clientY) / 4000;

            const initX = -Math.PI * 0.5;

            this.mouse.x = x;
            this.mouse.y = -y;

            gsap.to(this.group.rotation, {
                x: initX + this.mouse.x,
                y: this.mouse.y,
                duration: 2,
            });
        });
    }

    setNav() {
        const trigger = [...document.querySelectorAll(".mv__nav__arw")];
        const dotTrigger = [...document.querySelectorAll(".mv__nav__index__item")];

        let readyClick = true;
        let readyTimer = null;

        const onChange = (dir) => {
            this.loadNextTexture(this.slideIndex.count); //動的ロード
            this.setTitle(this.slideIndex.count); //ttlのチェンジ
            this.setDot(this.slideIndex.count); //dotのチェンジ
            this.meshList.forEach((mesh) => mesh.onChange(this.slideIndex.count, dir)); //meshのチェンジ
        };

        trigger.forEach((el) => {
            el.addEventListener("click", () => {
                if (!readyClick) {
                    return;
                }
                const dir = el.getAttribute("data-dir");
                dir === "next" ? this.slideIndex.count++ : this.slideIndex.count--;

                if (this.slideIndex.count < 0) {
                    this.slideIndex.count = this.textureURLArray.length - 1;
                } else if (this.slideIndex.count > this.textureURLArray.length - 1) {
                    this.slideIndex.count = 0;
                }

                onChange(dir);

                //連打防止対策
                readyClick = false;

                readyTimer = setTimeout(() => {
                    readyClick = true;
                    clearTimeout(readyTimer);
                }, 2000);
            });
        });

        //dotでクリックさせると順不同になるため、事前にすべてロードしないといけない：検討

        // dotTrigger.forEach((el, index) => {
        //     el.addEventListener("click", () => {
        //         this.slideIndex.count = index;
        //         onChange("next");
        //     });
        // });
    }

    createDot() {
        const dotwrapper = document.querySelector(".mv__nav__index");

        this.textureURLArray.forEach((el, index) => {
            const list = document.createElement("li");
            list.classList.add("mv__nav__index__item");

            if (index === this.slideIndex.count) {
                list.classList.add("-active");
            }
            dotwrapper.appendChild(list);
        });
    }

    setDot(slideIndex) {
        const dots = [...document.querySelectorAll(".mv__nav__index__item")];

        dots.forEach((dot, index) => {
            dot.classList.remove("-active");
            if (index === slideIndex) {
                dot.classList.add("-active");
            }
        });
    }

    setTitle(slideIndex) {
        const ttls = [...document.querySelectorAll(".mv__ttl")];

        ttls.forEach((ttl, index) => {
            ttl.classList.remove("-active");
            if (index === slideIndex) {
                ttl.classList.add("-active");
            }
        });
    }

    setTitleDivide() {
        const ttls = [...document.querySelectorAll(".mv__ttl")];

        ttls.forEach((ttl) => {
            const maintxt = ttl.querySelector(".main");
            new SpanWrap(maintxt);
        });
    }

    loadNextTexture(slideIndex) {
        //先読みするので現在のindex+1の画像をロードさせる
        const textureLoader = new THREE.TextureLoader();
        let next = slideIndex + 1;
        let prev = slideIndex - 1;

        if (next > this.textureURLArray.length - 1) {
            next = 0;
        }

        if (prev < 0) {
            prev = this.textureURLArray.length - 1;
        }

        //console.log(`current:${slideIndex}, next${next}, prev:${prev}`);

        //未ロードだったらロード
        if (!this.slideTexture[next]) {
            textureLoader.load(this.textureURLArray[next], (tex) => {
                this.slideTexture[next] = tex;
            });
        }

        //未ロードだったらロード
        if (!this.slideTexture[prev]) {
            textureLoader.load(this.textureURLArray[prev], (tex) => {
                this.slideTexture[prev] = tex;
            });
        }
    }

    onLoad() {
        document.querySelector("body").classList.add("-loaded");
        this.setTitle(this.slideIndex.count);

        this.meshList.forEach((mesh) => {
            mesh.onLoad();
        });

        //autoSlideStart
        this.autoSlide();
        this.setSlidePause();
    }

    onResize() {
        this.meshList.forEach((mesh) => {
            mesh.onResize();
        });
    }

    movePan(elapsed) {
        this.group.position.x = 0.25 - 0.5 * Math.sin(elapsed / 10);
    }

    onRaf(elapsed) {
        this.meshList.forEach((mesh) => {
            mesh.onRaf();
        });

        if (isSP()) {
            this.movePan(elapsed);
        }
    }

    clearTimer() {
        this.timer.forEach((el) => {
            clearTimeout(el);
        });
        this.timer = [];
    }

    setSlidePause() {
        const pauseElement = [...document.querySelectorAll(".js-slidepause")];
        const arw = [...document.querySelectorAll(".mv__nav__arw")];

        pauseElement.forEach((el) => {
            if (isSP()) {
                return;
            }
            el.addEventListener("mouseenter", () => {
                this.clearTimer();
                this.slidePause = true;
            });

            el.addEventListener("mouseleave", () => {
                if (this.timer.length === 0) {
                    this.slidePause = false;
                    this.autoSlide();
                }
            });
        });

        //SP用のタイマー解除
        arw.forEach((el) => {
            if (!isSP()) {
                return;
            }
            el.addEventListener("click", () => {
                this.clearTimer();
                this.slidePause = false;
                this.autoSlide();
            });
        });
    }

    autoSlide() {
        const trigger = document.querySelector(".mv__nav__arw[data-dir='next']");
        const event = new Event("click");
        const delay = 6000;

        const clickbtn = () => {
            trigger.dispatchEvent(event);

            if (!this.slidePause) {
                this.timer.push(setTimeout(clickbtn, delay));
            }
        };
        this.timer.push(setTimeout(clickbtn, delay));
    }
}
