import { isSP } from "../../functions/isSP";

export default class Cube {
    constructor(stage, index) {
        this.stage = stage;
        this.dom = null;
        this.domBox = null;
        this.rotation = 45;
        this.index = index;
        this.isTop = document.body.classList.contains("home");
        this.init();
    }

    init() {
        this.createDom();
    }

    createDom() {
        const element = document.createElement("div");
        this.dom = element;
        element.classList.add("l-bg__cube", "-grid2-160");

        const animClass = !document.body.classList.contains("top") ? "-dropanim" : "";

        element.innerHTML = `
            <div class="c-cube ${animClass}">
                <div class="boxBase -inbg">
                    <div class="top"></div>
                    <div class="bottom"></div>
                    <div class="front"></div>
                    <div class="back"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
            </div>
            `;

        this.domBox = this.dom.querySelector(".boxBase");
        this.stage.append(element);
        this.setPosition();
        this.initialRotate();
    }

    initialRotate() {
        if (this.domBox) {
            this.domBox.style.transform = `rotateX(${this.rotation}deg) rotateY(${this.rotation}deg)`;
        }
    }

    setPosition() {
        let windowHeight = 0;
        let offset = 0;
        let x = 0;

        if (document.body.classList.contains("top")) {
            windowHeight = window.innerHeight;
            offset = this.isTop ? windowHeight * 1.1 : windowHeight * 1.1;
            x = this.index % 2 === 0 ? window.innerWidth * 0.91 : window.innerWidth * -0.04;
        } else {
            windowHeight = Math.min(window.innerHeight, 1440); //最大値を設定
            offset = this.isTop ? 0 : windowHeight * 0.5;
            x = this.index % 2 !== 0 ? window.innerWidth * 0.91 : window.innerWidth * -0.04;
        }

        const y = isSP() ? (windowHeight / 2) * this.index + offset : (windowHeight / 2) * this.index + offset;

        this.dom.style.left = `${x}px`;
        this.dom.style.top = `${y}px`;
    }

    updatePosition(scrollTop = 0, scrollTopDiff = 0) {
        const k = 1.1;
        this.rotation += scrollTopDiff * 0.05;

        if (this.dom) {
            this.dom.style.transform = `translate(0,${-1 * scrollTop * k}px)`;
        }

        if (this.domBox) {
            this.domBox.style.transform = `rotateX(${this.rotation}deg) rotateY(${this.rotation}deg)`;
        }
    }

    onRaf(scrollTop, scrollTopDiff) {
        this.updatePosition(scrollTop, scrollTopDiff);
    }

    onResize() {
        this.setPosition();
    }
}
