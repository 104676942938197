//functions
import { gnav } from "./functions/gnav.es6";
import { modal } from "./functions/modal.es6";
import { setFitScreen } from "./functions/setFitScreen.es6";
import { grid } from "./functions/grid.es6";

//classes
import Scroll from "./classes/scroll.es6";
import Bg from "./classes/bg/main.es6";
import MV from "./classes/mv/main.es6";

import { isSP } from "./functions/isSP";

export default class Main {
    constructor() {
        this.init();
    }

    init() {
        //functions
        gnav();
        modal();
        grid();
        setFitScreen();

        //classes
        this.scroll = new Scroll();
        this.bg = new Bg();

        //three.jsのインスタンスが重複しないように同じJSでthree.jsの処理は書く
        if (document.querySelector(".mvgl")) {
            this.mv = new MV();
        }

        if (!isSP()) {
            window.addEventListener("resize", setFitScreen);
        }
    }
}
