import * as THREE from "three";
import { GUI } from "dat.gui";

import { gsap } from "gsap";
import { isSP } from "../../functions/isSP";

export class Stage {
    constructor() {
        this.rendererParam = {
            clearColor: 0xff9d00,
            width: this.getCVsize().width,
            height: this.getCVsize().height,
        };

        this.v = new THREE.Vector2(0.1, 0.1);

        this.renderedStyles = {
            tx: { previous: 0, current: 0, amt: 0.8 },
            ty: { previous: 0, current: 0, amt: 0.8 },
        };
        this.mouse = { x: 0, y: 0 };

        this.cameraRad = 0;

        this.cameraParam = {
            fov: 10,
            near: 0.1,
            far: 1000.0,
            lookAt: new THREE.Vector3(0, 0, 0),
            x: 0,
            y: 0,
            z: 1,
        };

        this.scene = null; // シーン
        this.camera = null; // カメラ
        this.renderer = null; // レンダラ
        this.geometry = null; // ジオメトリ
        this.material = null; // マテリアル
        this.mesh = null; // ボックスメッシュ
        this.nrw = window.innerWidth <= 768;

        this.isInitialized = false;
    }

    init() {
        this.setScene();
        this.setRenderer();
        this.setCamera();

        //GUIset
        //this.setGUI();

        if (!isSP()) {
            this.setMouseEvent();
        }

        this.isInitialized = true;
    }

    setGUI() {
        const gui = new GUI();
        const cameraPosition = gui.addFolder("CameraPosition");
        cameraPosition.add(this.camera.rotation, "x", -1, 1);
        cameraPosition.add(this.camera.rotation, "y", -1, 1);
        cameraPosition.add(this.camera.rotation, "z", -1, 1);
        cameraPosition.add(this.camera.position, "x", 0, 10);
        cameraPosition.add(this.camera.position, "y", 0, 10);
        cameraPosition.add(this.camera.position, "z", 0, 10);
    }

    setMouseEvent() {
        window.addEventListener("mousemove", (e) => {
            const x = (window.innerWidth / 2 - e.clientX) / 1500;
            const y = (window.innerHeight / 2 - e.clientY) / 1500;

            this.mouse.x = x;
            this.mouse.y = -y;

            const r = 5.25;
            const initRad = Math.PI * 1.75;
            const cameraY = 4;
            const cameraX = r * Math.cos(initRad + this.mouse.x);
            const cameraZ = -1 * r * Math.sin(initRad + this.mouse.y); //zは手前が+のため

            gsap.to(this.camera.position, {
                x: cameraX,
                y: cameraY,
                z: cameraZ,
                onUpdate: () => {
                    this.camera.lookAt(this.cameraParam.lookAt);
                },
                duration: 4,
            });
        });
    }

    getCVsize() {
        //const w = window.innerWidth;
        //position:fixedではなく、absoluteなので、window用にスクロールバーを含まない幅にする
        const w = document.body.clientWidth;
        const h = window.innerHeight;

        return { width: w, height: h };
    }

    setScene() {
        this.scene = new THREE.Scene();
    }

    setRenderer() {
        const canvas = document.querySelector("canvas.mvgl");
        this.renderer = new THREE.WebGLRenderer({ canvas: canvas, alpha: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(new THREE.Color(this.rendererParam.clearColor), 1);
        this.renderer.setSize(this.rendererParam.width, this.rendererParam.height);
    }

    setCamera() {
        if (!this.isInitialized) {
            this.camera = new THREE.PerspectiveCamera(
                this.cameraParam.fov, // fov
                0, // aspect
                this.cameraParam.near,
                this.cameraParam.far
            );

            //this.camera.position.set(this.cameraParam.x, this.cameraParam.y, this.cameraParam.z);
            //this.camera.position.set(3.5, 3.5, 3.5);
            //this.camera.lookAt(this.cameraParam.lookAt);

            //const r = 4.0;
            const r = isSP() ? 6 : 5.25;

            //const cameraY = 3.5;
            const cameraY = isSP() ? 6 : 4.5;
            const cameraX = r * Math.cos(Math.PI * 1.75);
            const cameraZ = -1 * r * Math.sin(Math.PI * 1.75); //zは手前が+のため

            this.camera.position.set(cameraX, cameraY, cameraZ);
            this.camera.lookAt(this.cameraParam.lookAt);
        }

        this.camera.aspect = this.getCVsize().width / this.getCVsize().height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.getCVsize().width, this.getCVsize().height);
    }

    render() {
        this.renderer.render(this.scene, this.camera);
    }

    onResize() {
        this.setCamera();
    }

    movePan(elapsed) {
        const r = 6;
        const initRad = Math.PI * 1.75;
        const cameraX = r * Math.cos(initRad + elapsed / 10);
        //const cameraZ = -1 * r * Math.sin(initRad + elapsed / 10); //zは手前が+のため

        //this.camera.position.x =(cameraX, cameraY, cameraZ);
        this.camera.position.x = cameraX;
        this.camera.lookAt(this.cameraParam.lookAt);
    }

    onRaf(elapsed) {
        this.render();

        if (isSP()) {
            this.movePan(elapsed);
        }
    }
}
