import Cube from "./cube.es6";
import { isSP } from "../../functions/isSP.es6";

export default class Bg {
    constructor() {
        this.cubeList = [];
        this.init();
    }

    init() {
        this.createDom();
        this.onScroll();
    }

    createDom() {
        const stage = document.querySelector(".l-bg");

        const amount = Math.floor(15 * (1080 / window.innerHeight));

        for (let i = 0; i < amount; i++) {
            const cube = new Cube(stage, i);
            this.cubeList.push(cube);
        }

        window.addEventListener("resize", this.onResize.bind(this));
    }

    onScroll() {
        const lerp = (a, b, n) => (1 - n) * a + n * b;
        let scrollTop = 0;
        let scrollTopPrev = 0;
        let scrollTopDiff = 0;

        //scrollFunctions
        let ticking = false;

        const scrollfunc = () => {
            //requestanimationframeはモバイル実機でフレームレートが落ちたのでヤメ
            // if (!ticking) {
            //     requestAnimationFrame(() => {
            //         ticking = false;
            //         scrollTop = window.scrollY;
            //         scrollTopDiff = lerp(scrollTopDiff, scrollTop - scrollTopPrev, 0.1);

            //         this.cubeList.forEach((cube) => {
            //             cube.onRaf(scrollTop, scrollTopDiff);
            //         });

            //         scrollTopPrev = scrollTop;
            //     });
            //     ticking = true;
            // }

            scrollTop = window.scrollY;
            scrollTopDiff = lerp(scrollTopDiff, scrollTop - scrollTopPrev, 0.1);

            //lerpも慣性スクロールの関係からPCのみに変更
            if (!isSP()) {
                scrollTopDiff = lerp(scrollTopDiff, scrollTop - scrollTopPrev, 0.1);
            } else {
                scrollTopDiff = scrollTop - scrollTopPrev;
            }

            this.cubeList.forEach((cube) => {
                cube.onRaf(scrollTop, scrollTopDiff);
            });

            scrollTopPrev = scrollTop;
        };

        window.addEventListener("scroll", scrollfunc.bind(this), { passive: true });
    }

    onResize() {
        this.cubeList.forEach((cube) => {
            cube.onResize();
        });
    }
}
