import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { isSP } from "../functions/isSP";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
export default class Scroll {
    constructor() {
        this.init();
    }

    init() {
        this.set();
        this.smoothScroll();
    }

    set() {
        const revealElm = [...document.querySelectorAll(".js-scrollreveal")];
        const mvsection = [...document.querySelectorAll(".js-mv")];

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 80%",
                //markers: true,
                onEnter: (self) => {
                    el.classList.add("-reveal");
                },
            });
        });

        mvsection.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                end: "bottom 56px",
                onLeave: (self) => {
                    document.querySelector(".l-header").classList.add("-bk");
                },
                onEnterBack: (self) => {
                    document.querySelector(".l-header").classList.remove("-bk");
                },
            });
        });
    }

    smoothScroll() {
        const triggers = document.querySelectorAll(".js-smoothscroll");
        const pagetops = document.querySelectorAll(".js-pagetop");

        triggers.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const hash = el.getAttribute("href");
                const offset = isSP() ? window.innerWidth * 0.3 : 200 * (window.innerWidth / 1920);
                gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: offset }, ease: "circ.inOut" });
            });
        });

        pagetops.forEach((el) => {
            el.addEventListener("click", (e) => {
                gsap.to(window, { duration: 1, scrollTo: { y: 0 }, ease: "circ.inOut" });
            });
        });
    }
}
