import { isSP } from "./isSP";

const gnav = () => {
    if (!document.querySelector(".l-gnav__trigger")) {
        return;
    }

    const body = document.body;
    const trigger = document.querySelector(".l-gnav__trigger");

    trigger.addEventListener("click", () => {
        if (!isSP()) {
            return;
        }
        if (trigger.getAttribute("data-open") === "0") {
            body.classList.add("-lock");
            body.classList.add("-open");
            trigger.setAttribute("data-open", "1");
        } else {
            body.classList.remove("-lock");
            body.classList.remove("-open");
            trigger.setAttribute("data-open", "0");
        }
    });
};

export { gnav };
