const modal = () => {
    const openbuttons = [...document.querySelectorAll(".js-modal_open")];
    const closebuttons = [...document.querySelectorAll(".js-modal_close")];
    const modals = [...document.querySelectorAll(".js-modal")];

    openbuttons.forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();

            const modalID = el.getAttribute("data-modal");
            const tgtmodal = document.querySelector(`.js-modal[data-modal=${modalID}]`);
            tgtmodal && tgtmodal.classList.add("-active");
            document.body.classList.add("-lock");
        });
    });

    closebuttons.forEach((el) => {
        el.addEventListener("click", () => {
            modals.forEach((el) => el.classList.remove("-active"));
            document.body.classList.remove("-lock");
        });
    });
};

export { modal };
