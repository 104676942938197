import { Stage } from "./stage";
import { Slide } from "./slide.es6";
import { isSP } from "../../functions/isSP";

export default class MV {
    constructor() {
        this.init();
    }

    init() {
        const stage = new Stage();
        const slide = new Slide(stage);
        stage.init();
        slide.init();
        let starttime = null;

        //スマホではスクロールでリサイズが起こるのでリサイズハンドラは使わない
        if (!isSP()) {
            window.addEventListener("resize", () => {
                stage.onResize();
                slide.onResize();
            });
        }

        const raf = (timestamp) => {
            if (!starttime) {
                starttime = timestamp;
            }

            const runtime = timestamp - starttime;

            requestAnimationFrame(raf);
            stage.onRaf(runtime / 1000);
            slide.onRaf(runtime / 1000);
        };

        raf();
    }
}
