import * as THREE from "three";
import { gsap } from "gsap";
import { isSP } from "../../functions/isSP";

export class Mesh {
    constructor({ stage, index, group, slideTexture, gridTexture, initialIndex }) {
        this.count = 1;
        this.index = index;
        this.initialIndex = initialIndex;
        this.slideTexture = slideTexture;
        this.gridTexture = gridTexture;
        this.stage = stage;
        this.group = group;
        this.position = new THREE.Vector3(0, 0, 0);
        this.size = { x: 0.2, y: 0.2, z: 0.2 };

        this.geometryParm = {
            width: 1,
            height: 1,
            depth: 1,
            segments: 1,
        };
        this.geometry = null;
        this.material = null;
        this.mesh = null; // mesh
    }

    init() {
        this.setMesh();
    }

    setMesh() {
        // ジオメトリーを生成
        this.geometry = new THREE.BoxBufferGeometry(this.geometryParm.width, this.geometryParm.height, this.geometryParm.depth);

        const uvAttribute = this.geometry.attributes.uv;

        for (let i = 0; i < uvAttribute.count; i++) {
            let u = uvAttribute.getX(i);
            let v = uvAttribute.getY(i);
            //console.log(`(${u}, ${v})`);
            const x_devide = 8;
            const y_devide = 5;
            const grid = 8;
            const x_index = this.index % grid;
            const y_index = Math.floor(this.index / grid);
            //天面UVの加工
            if (i >= 16 && i <= 19) {
                //アルゴリズム化できた！！！正方形分割
                if (u === 0) {
                    u = (1 / x_devide) * x_index;
                }
                if (v === 0) {
                    v = (1 / y_devide) * y_index;
                }
                if (u === 1) {
                    u = (1 / x_devide) * (x_index + 1);
                }
                if (v === 1) {
                    v = (1 / y_devide) * (y_index + 1);
                }
            }
            uvAttribute.setXY(i, u, v);
        }

        const materialArray = [];
        const topSurface = new THREE.MeshBasicMaterial({
            map: this.slideTexture[this.initialIndex],
        });
        const whiteSurface = new THREE.MeshBasicMaterial({
            map: this.gridTexture,
        });

        for (let i = 0; i < 6; i++) {
            if (i === 4) {
                materialArray.push(topSurface);
            } else {
                materialArray.push(whiteSurface);
            }
        }

        // マテリアルを生成(6面分作る)
        this.material = materialArray;
        this.mesh = new THREE.Mesh(this.geometry, this.material);

        //scale and position
        this.setMeshScale(this.size.x, this.size.y, this.size.z);
        this.setPosition();

        //groupへのmesh追加
        this.group.add(this.mesh);
    }

    setMeshScale(w, h, d) {
        this.mesh.scale.x = w;
        this.mesh.scale.y = h;
        this.mesh.scale.z = d;
    }

    setPosition() {
        const grid_x = 8;
        const grid_y = 5;
        const row = Math.floor(this.index / grid_x);
        const w = this.mesh.scale.x * grid_x;
        const h = this.mesh.scale.y * grid_y;
        const x = this.mesh.scale.x * (this.index - row * grid_x) + this.mesh.scale.x / 2 - w / 2;
        const y = this.mesh.scale.y * row + this.mesh.scale.y / 2 - h / 2;
        const z = -3; //animation init
        this.position.set(x, y, z);
    }

    onResize() {}

    onRaf() {
        if (this.mesh) {
            this.mesh.position.x = this.position.x;
            this.mesh.position.y = this.position.y;
            this.mesh.position.z = this.position.z;
        }
    }

    changeMaterial(currentIndex) {
        if (this.slideTexture[currentIndex]) {
            this.material[4].map = this.slideTexture[currentIndex];
        }
    }

    onChange(nextIndex, dir) {
        this.count++;

        const randDelay = this.index * 0.025;
        const duration = 0.6;
        const rotationDir = dir === "next" ? 1 : -1;
        gsap.to(this.mesh.scale, { x: this.size.x / 2, y: this.size.x / 2, z: this.size.z / 2, duration: duration / 2, ease: "circ.out" });
        gsap.to(this.mesh.rotation, { y: rotationDir * Math.PI, duration: duration, ease: "circ.out" });

        gsap.to(this.mesh.rotation, { y: 0, duration: duration, ease: "circ.inOut", delay: duration + randDelay });
        gsap.to(this.mesh.scale, { x: this.size.x, y: this.size.y, z: this.size.z, duration: duration, ease: "circ.inOut", delay: 0.5 + randDelay });

        setTimeout(() => {
            this.changeMaterial(nextIndex);
        }, randDelay * 1000 + duration * 1000 + 500 / 2);
    }

    onLoad() {
        gsap.to(this.position, { z: 0, ease: "expo.inOut", duration: 1, delay: Math.random() });
    }
}
