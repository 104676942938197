import { isSP } from "./isSP";

const grid = () => {
    const setRemain = () => {
        const remain = isSP() ? window.innerWidth % 28 : window.innerWidth % 32;
        document.documentElement.style.setProperty("--gridRemainder", `${remain}px`);
    };

    setRemain();
    window.addEventListener("resize", setRemain);
};

export { grid };
